import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { ModalContext } from "../../../components/contexts/ModalContext"
import AddPersonModal from "../components/AddPersonModal"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getEmployeeByIdData, getUserGroupsFromKC } from "../api/apiCalls"
import { Employee } from "../types/Types"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import {
    useAddDocToEmployeeMutation,
    usePWResetEmployeeMutation,
    usePatchDocEmployeeMutation,
    useUpdateEmployeeKcGroupMutation,
    useUpdateEmployeeMutation,
} from "../api/services"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import BaseAddDocumentModal from "../../../components/elements/BaseAddDocumentModal"
import { useArchiveDataMutation, useDeleteDataMutation, useDownloadDocumentMutation } from "../../api/services"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import EmployeePartLeft from "../components/EmployeePartLeft"
import EmployePartRight from "../components/EmployePartRight"
import { useNavigate, useParams } from "react-router-dom"
import BasePreview from "../../../components/elements/BasePreview"
import { API } from "../../../api/constants"
import { getPaginatedData } from "../../api/apiCalls"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"

export default function Mitarbeiter() {
    const { id } = useParams()

    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)

    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["employeeData" + page + searchParams],
        queryFn: () => getPaginatedData("employee", page, 20, searchParams),
    })
    console.log(data)

    const {
        data: kcData,
        error: kcError,
        isLoading: kcIsLoading,
    } = useQuery({
        queryKey: ["kcUserGroups"],
        queryFn: getUserGroupsFromKC,
    })
    // get single employee by id
    const {
        error: singleEmployeeError,
        data: singleEmployeeData,
        isLoading: singleEmployeeIsLoading,
    } = useQuery({
        queryKey: ["employeeData" + id],
        queryFn: () => getEmployeeByIdData(id ?? ""),
        enabled: !!id,
    })
    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const employeeDownloadUrl = useMemo(() => {
        return error && !isLoading ? undefined : API + `/employee/${id}/download?s3Key=${selectedS3Key}`
    }, [error, isLoading, selectedS3Key, id])

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const [rbacGroup, setrbacGroup] = useState("")
    const [selectedEmployee, setselectedEmployee] = useState<Employee>({
        _id: "",
        first_name: "",
        last_name: "",
        geb: "",
        email: "",
        position: "",
        tasks: [],
        objects: [],
        contractStart: "",
        kostenstelle: "",
        vehicle: false,
        trailperiod: false,
    })
    const [selectedEmployeeCopy, setselectedEmployeeCopy] = useState<Employee>()

    // select first employee id
    const navigate = useNavigate()
    useEffect(() => {
        if (data?.docs) {
            navigate("/mitarbeiter/" + data?.docs.find((employee: Employee) => employee.archived === false)?._id)
        }
    }, [data])

    // select employee after loading single employee via id
    useEffect(() => {
        if (singleEmployeeData?.rbacGroup?.name) {
            setselectedEmployee(singleEmployeeData)
            setselectedEmployeeCopy(singleEmployeeData)
            setrbacGroup(singleEmployeeData.rbacGroup.name)
        }
    }, [singleEmployeeData])

    // Update & Archive & Delete Employee
    const { mutate: archiveCustomer } = useArchiveDataMutation(queryClient, () => modalCon?.trigger(3), "employeeData" + page, "Mitarbeiterstatus")
    const handleArchiveEmployee = (archive: boolean) => {
        archiveCustomer({
            id: id ?? "",
            body: { archived: archive },
            pageType: "employee",
        })
    }
    const { mutate: deleteEmployee } = useDeleteDataMutation(queryClient, modalCon, "Mitarbeiter", "employeeData" + page)
    const handleDeleteEmployee = () => {
        deleteEmployee({ id: id ?? "", pageType: "employee" })
    }

    // reset Employee Password
    const { mutate: resetPW } = usePWResetEmployeeMutation(queryClient, modalCon, id ?? "")
    const resetPassword = () => {
        console.log(selectedEmployee.kcId)
        resetPW(selectedEmployee.kcId ?? "  ")
    }
    const { mutate: updateEmployee } = useUpdateEmployeeMutation(queryClient, id ?? "")
    const { mutate: updateEmployeeKcGroup } = useUpdateEmployeeKcGroupMutation(queryClient, id ?? "")
    const handleUpdateEmployee = () => {
        const updateEmployeeData: Employee = {
            last_name: selectedEmployee.last_name,
            first_name: selectedEmployee.first_name,
            geb: selectedEmployee.geb,
            email: selectedEmployee.email,
            position: selectedEmployee.position,
            contractStart: selectedEmployee.contractStart,
            contractEnd: selectedEmployee.contractEnd ?? undefined,
            vehicle: selectedEmployee.vehicle,
            trailperiod: selectedEmployee.trailperiod,
            notes: selectedEmployee.notes ?? undefined,
            kostenstelle: selectedEmployee.kostenstelle ?? undefined,
        }
        if (rbacGroup !== selectedEmployee.position) {
            updateEmployeeKcGroup({
                kcid: singleEmployeeData.kcId,
                gid: kcData.rbac.find((item: { name: string; id: any }) => item.name === rbacGroup).id, // TODO Keycloak Gruppe
            })
        }
        updateEmployee({
            id: selectedEmployee._id ?? "",
            body: updateEmployeeData,
        })
    }

    // add file
    const { mutate: addDoc, status } = useAddDocToEmployeeMutation(queryClient, id ?? "")

    // download file from employee
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: selectedEmployee._id ?? "", s3Key: s3Key, filetype: "employee", filename: filename })
    }

    // delete file
    const [fileToDelete, setfileToDelete] = useState<string>("")
    const { mutate: deleteDoc } = usePatchDocEmployeeMutation(queryClient, modalCon, 6, id ?? "")
    const handleDeleteDoc = () => {
        deleteDoc({
            id: id ?? "",
            file: fileToDelete,
        })
    }

    // filter settings & searchbar
    const [showFilter, setShowFilter] = useState(false)
    const [filter, setFilter] = useState(0)
    const [archiveString, setarchiveString] = useState("hidden")
    const [positionString, setpositionString] = useState("all")

    const handleFilterRemove = () => {
        setarchiveString("hidden")
        setpositionString("all")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setShowFilter(false)
        setFilter(filter + 1)
    }

    const filterArchived = useCallback(
        (data: Employee[]) => {
            if (archiveString === "hidden") {
                return data?.filter((item) => item.archived === false)
            } else if (archiveString === "show") {
                return data
            } else if (archiveString === "only") {
                return data?.filter((item) => item.archived === true)
            } else {
                return data
            }
        },
        [filter]
    )

    const filterPosition = useCallback(
        (data: Employee[]) => {
            if (positionString === "all") {
                return data
            } else if (positionString === "4") {
                return data?.filter((item) => item.position === "Mitarbeiter")
            } else if (positionString === "3") {
                return data?.filter((item) => item.position === "Backoffice 3")
            } else if (positionString === "2") {
                return data?.filter((item) => item.position === "Backoffice 2")
            } else if (positionString === "1") {
                return data?.filter((item) => item.position === "Backoffice 1")
            } else if (positionString === "0") {
                return data?.filter((item) => item.position === "Admin")
            } else {
                return data
            }
        },
        [filter]
    )

    const displayedEmployee = useMemo(() => {
        if (data?.docs) {
            const positionData = filterPosition(data.docs)
            return filterArchived(positionData) ?? data?.docs
        }
    }, [data, filterArchived, filterPosition])

    return (
        <BasePage>
            <HeadBanner
                title="Mitarbeiter"
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_create, Roles.mitarbeiter_read_write]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden lg:block"
                            onClick={() => modalCon?.trigger(1)}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            <section className="w-full flex flex-col sm:flex-row justify-between gap-4 py-4 md:px-4 relative h-full">
                {/* LEFT Part */}
                <EmployeePartLeft
                    error={error}
                    isLoading={isLoading}
                    searchTerm={searchTerm}
                    setsearchTerm={setsearchTerm}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    archiveString={archiveString}
                    setarchiveString={setarchiveString}
                    handleUseFilter={handleUseFilter}
                    handleFilterRemove={handleFilterRemove}
                    positionString={positionString}
                    setpositionString={setpositionString}
                    displayedEmployee={displayedEmployee ?? []}
                    selectedEmployee={selectedEmployee}
                    page={page}
                    totalDocs={data?.totalDocs}
                    handlePageChange={() => handlePageChange}
                />
                {kcError && <ErrorPage />}
                {(kcIsLoading || status === "pending") && <Loading />}
                {!kcError && !kcIsLoading && kcData && data && singleEmployeeData && (
                    <>
                        {/* Detailed Information */}
                        {singleEmployeeIsLoading && <Loading />}
                        {singleEmployeeError && <ErrorPage />}
                        {singleEmployeeData && !singleEmployeeError && !singleEmployeeIsLoading && (
                            <EmployePartRight
                                setselectedS3Key={setselectedS3Key}
                                rbacGroup={rbacGroup}
                                setrbacGroup={setrbacGroup}
                                selectedEmployee={selectedEmployee}
                                selectedEmployeeCopy={selectedEmployeeCopy}
                                setselectedEmployee={setselectedEmployee}
                                handleUpdateEmployee={handleUpdateEmployee}
                                setfileToDelete={setfileToDelete}
                                handleDownload={handleDownload}
                                kcData={kcData}
                            />
                        )}
                    </>
                )}
            </section>
            <AddPersonModal />
            <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(2)} modalId={2} selectedId={selectedEmployee?._id ?? ""} />
            <ModalCheckAction
                modalId={3}
                headline={"Wirklich Archivieren?"}
                text={
                    "Sind Sie sich sicher, diesen Kunden archivieren wollen? Er wird nicht mehr in der Liste Ihrer aktiven Kunden angezeigt werden."
                }
                buttonText={"Archivieren"}
                buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                func={() => handleArchiveEmployee(true)}
            />
            <ModalCheckAction
                modalId={4}
                headline={"Wirklich Löschen?"}
                text={"Sind Sie sich sicher, diesen Mitarbeiter zu löschen? Alle dazugehörigen Daten werden ebenfalls gelöscht."}
                buttonText={"Löschen"}
                buttonColor="btn-error"
                func={handleDeleteEmployee}
            />
            <ModalCheckAction
                modalId={5}
                headline={"Archivieren rückgängig machen?"}
                text={
                    "Sind Sie sich sicher, diesen Mitarbeiter nicht mehr archivieren wollen? Er wird ab sofort wieder in der Liste Ihrer aktiven Mitarbeiter angezeigt."
                }
                buttonText={"Anzeigen"}
                buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                func={() => handleArchiveEmployee(false)}
            />
            <ModalCheckAction
                modalId={6}
                headline={"Dokument löschen?"}
                text={"Sind Sie sich sicher, dass Sie dieses Dokument wollen?"}
                buttonText={"Löschen"}
                buttonColor="btn-error"
                func={handleDeleteDoc}
            />
            <ModalCheckAction
                modalId={7}
                headline={"Wirklich Zurücksetzen?"}
                text={
                    "Sind Sie sich sicher, dass Passwort dieses Mitarbeiters zurücksetzen wollen? Der Nutzer erhält eine E-Mail mit einem Link, um das Passwort zu ändern."
                }
                buttonText={"Zurücksetzen"}
                buttonColor="btn-primary"
                func={resetPassword}
            />
            <BasePreview title={"Dokumentvorschau"} sidePanelId={8} previewType={"document"} content={employeeDownloadUrl} />
        </BasePage>
    )
}
