import { message, Upload } from "antd"
import { ReactComponent as PLUS } from "../../../assets/PlusICON.svg"
import { Loading } from "react-daisyui"
import { useState } from "react"
import { useAddImageToObjectMutation } from "../api/services"
import { QueryClient, useQuery } from "@tanstack/react-query"
import { API } from "../../../api/constants"
import { getImageFromObject } from "../api/apiCalls"

type BaseImageUploadType = {
    s3RefIsExisting: boolean
    objectId: string
}

export default function BaseImageUpload(props: BaseImageUploadType) {
    const [loading, setLoading] = useState(false)
    console.log(API + "/object/image/" + props.objectId)

    const { data, error, isLoading } = useQuery({
        queryFn: () => getImageFromObject(props.objectId),
        queryKey: ["singleObject" + props.objectId],
        enabled: props.s3RefIsExisting,
    })

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!")
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!")
            return false
        }
        return true
    }
    // Verwende den bestehenden Hook
    const queryClient = new QueryClient()
    const { mutate: addImage } = useAddImageToObjectMutation(queryClient, setLoading)

    // Upload the new image
    const customUpload = async (options: any) => {
        const { file } = options
        setLoading(true)
        const formData = new FormData()
        formData.append("image", file)
        // Führe die Mutation aus, um das Bild hochzuladen
        addImage({ id: props.objectId, img: formData })
    }
    return (
        <div className="flex justify-center w-full">
            <Upload
                name="image" // 'image' should match the multer field name in your backend
                // listType={"picture-card"}
                className={`w-96 h-32 items-center justify-center flex rounded-md border-black border border-dashed mb-2 overflow-hidden ${
                    !props.s3RefIsExisting && "border-dashed"
                }`}
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={customUpload}
                headers={{ authorization: "Bearer your-auth-token" }} // Include auth header if needed
            >
                {props.s3RefIsExisting ? (
                    <img src={data ?? ""} alt="avatar" className="w-96 h-32 object-cover object-center" />
                ) : (
                    <button style={{ border: 0, background: "none" }} className="flex flex-col justify-center items-center" type="button">
                        {loading ? <Loading /> : <PLUS className="w-6 h-6" />}
                        <p>Bild des Objekts</p>
                    </button>
                )}
            </Upload>
        </div>
    )
}
