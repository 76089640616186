import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { PR } from "../../../data/Types"
import PaymentTableRow from "../components/payment/PaymentTableRow"
import PaymentFilterModal from "../components/payment/PaymentFilterModal"
import RecheckPaymentModal from "../components/payment/paymentRecheckModal/RecheckPaymentModal"
import SolveEscaltionModal from "../components/payment/solveEscaltion/SolveEscaltionModal"
import { useSolveEscalationMutation } from "../api/services"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"

export default function PaymentPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination

    const [searchTermCustomer, setsearchTermCustomer] = useState("")
    const [searchTermNr, setsearchTermNr] = useState("")
    const [searchTerm, setsearchTerm] = useState("")
    const handleInputChange = (setter: (e: string) => void, reset1: (e: string) => void, e: any) => {
        setter(e)
        reset1("")
        setsearchTerm(e)
    }
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["paymentsData" + page + searchParams],
        queryFn: () => getPaginatedData("paymentrequirements", page, 15, searchParams),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [sort, setsort] = useState(false)
    const [payment, setpayment] = useState<PR>()

    // NOTE -> used in work with Filter Modal
    const [showFilter, setshowFilter] = useState(false)
    const [filter, setFilter] = useState(0)
    const [filterType, setfilterType] = useState("")
    const [filterStatus, setfilterStatus] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")

    const queryClient = useQueryClient()
    const [solvingId, setSolvingId] = useState("")
    const { mutate: solveEscalationFunction } = useSolveEscalationMutation(queryClient, modalCon, 1)
    const solveEscalation = () => {
        console.log(solvingId)
        solveEscalationFunction(solvingId)
    }

    const handleChangeStatus = useCallback((textContent: string) => {
        setfilterStatus(textContent)
    }, [])

    const handleChangeType = useCallback((textContent: string) => {
        setfilterType(textContent)
    }, [])

    const handleRemoveFilter = () => {
        setfilterStatus("")
        setfilterType("")
        setfilterDateTo("")
        setfilterDateSince("")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setshowFilter(false)
        setFilter(filter + 1)
    }

    const sortDates = useCallback(
        (data: PR[]) => {
            if (sort) {
                return data?.sort((a, b) => new Date(a.invoice_date).getTime() - new Date(b.invoice_date).getTime())
            } else {
                return data?.sort((a, b) => new Date(b.invoice_date).getTime() - new Date(a.invoice_date).getTime())
            }
        },
        [sort]
    )

    const filterDates = useCallback(
        (data: PR[]) => {
            if (filterDateSince === "" && filterDateTo === "") {
                return data
            } else {
                const returnData = data.filter((item) => {
                    const itemDate = dayjs(item.invoice_date)
                    const isAfterStartDate = filterDateSince !== "" ? itemDate.isAfter(dayjs(filterDateSince)) : true
                    const isBeforeEndDate = filterDateTo !== "" ? itemDate.isBefore(dayjs(filterDateTo)) : true
                    return isAfterStartDate && isBeforeEndDate
                })
                return returnData
            }
        },
        [filter]
    )

    const filterData = useCallback(
        (data: PR[]) => {
            if (filter) {
                return data?.filter(
                    (item) => (filterType === "" || filterType === item.bill_id.billType) && (filterStatus === "" || filterStatus === item.status)
                )
            } else {
                return data
            }
        },
        [filter]
    )

    // NOTE currently only search function for customer - maybe for bill title needed as well?
    const displayedPayReqs = useMemo(() => {
        if (data?.docs) {
            const sortData = sortDates(data.docs)
            // const archivedData = filterArchived(sortData);
            const filteredData = filterData(sortData)
            return filterDates(filteredData)
        }
    }, [sortDates, data?.docs, filterData, filterDates])

    return (
        <BasePage>
            <HeadBanner title={"Zahlungsaufforderungen"} />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative max-h-[18vh]">
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => handleInputChange(setsearchTermNr, setsearchTermCustomer, e.target.value)}
                        type="text"
                        value={searchTermNr ?? null}
                        placeholder="Nach Rechnungsnummer suchen..."
                        className="w-full sm:w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => handleInputChange(setsearchTermCustomer, setsearchTermNr, e.target.value)}
                        type="text"
                        value={searchTermCustomer ?? null}
                        placeholder="Nach Kunden suchen..."
                        className="hidden xl:block w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/angebote")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                        </svg>
                        Angebote
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/rechnungen")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                            />
                        </svg>
                        Rechnungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setsort(!sort)
                        }}>
                        {sort ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                            </svg>
                        )}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <PaymentFilterModal
                        visible={showFilter}
                        dateSince={filterDateSince}
                        dateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        changeDateSince={setfilterDateSince}
                        useFilter={handleUseFilter}
                        filterStatus={filterStatus}
                        filterChangeStatus={handleChangeStatus}
                        filterType={filterType}
                        filterChangeType={handleChangeType}
                        filterRemove={handleRemoveFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" text-base sm:w-12 h-full items-center justify-center"></th>
                                    <th className=" hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Erstellungsdatum</th>
                                    <th className=" text-base w-fit text-center truncate">{window.innerWidth > 640 ? "Rechnungsnummer" : "Nr."}</th>
                                    <th className=" text-base sm:min-w-[8rem] w-fit text-center">Betrag</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden xl:table-cell text-base min-w-[10rem] w-fit text-center">Überfällig</th>
                                    <th className="w-fit"></th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedPayReqs?.map((item) => (
                                    <PaymentTableRow
                                        key={item?._id}
                                        id={item?.bill_id?._id}
                                        title={item?.bill_id?.nr}
                                        customer={item?.customer_id?.customer?.name}
                                        status={item?.status}
                                        date={item?.invoice_date}
                                        delivery={item?.due_date}
                                        amount={item?.expected_payment}
                                        type={item?.bill_id?.billType}
                                        archived={false}
                                        escalationRequired={item?.escalationRequired}
                                        openSolveEscalationModal={() => {
                                            setSolvingId(item._id)
                                            modalCon?.trigger(1)
                                        }}
                                        modalOpen={() => {
                                            setpayment(item)
                                            modalCon?.trigger(2)
                                        }}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedPayReqs || displayedPayReqs.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erwarteten Zahlungen</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            current={page}
                            pageSize={15}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <RecheckPaymentModal payment={payment} />
            <SolveEscaltionModal modalId={1} solveEscalation={solveEscalation} />
        </BasePage>
    )
}
