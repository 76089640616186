import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import BillTableRow from "../components/bill/BillTableRow"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import BillFilterModal from "../components/bill/BillFilterModal"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import AddBillModal from "../components/addBillModal/AddBillModal"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Rechnung } from "../../../data/Types"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"

export default function BillPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const [searchTermNr, setsearchTermNr] = useState("")
    const [searchTermCustomer, setsearchTermCustomer] = useState("")
    const [searchTermObject, setsearchTermObject] = useState("")
    const handleInputChange = (setter: (e: string) => void, reset1: (e: string) => void, reset2: (e: string) => void, e: any) => {
        setter(e)
        reset1("")
        reset2("")
        setsearchTerm(e)
    }
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)

    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["billData" + page + searchParams],
        queryFn: () => getPaginatedData("bill", page, 15, searchParams),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [sort, setsort] = useState(false)

    // NOTE -> used in work with Filter Modal
    const [showFilter, setshowFilter] = useState(false)
    const [filter, setFilter] = useState(0)
    const [filterType, setfilterType] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")
    const [archived, setarchived] = useState("hidden")

    const handleChangeType = useCallback((textContent: string) => {
        setfilterType(textContent)
    }, [])

    const handleRemoveFilter = () => {
        setfilterType("")
        setfilterDateTo("")
        setfilterDateSince("")
        setarchived("hidden")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setshowFilter(false)
        setFilter(filter + 1)
    }

    const sortDates = useCallback(
        (data: Rechnung[]) => {
            if (sort) {
                return data?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            } else {
                return data?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            }
        },
        [sort]
    )

    const filterDates = useCallback(
        (data: Rechnung[]) => {
            if (filterDateSince === "" && filterDateTo === "") {
                return data
            } else {
                const returnData = data.filter((item) => {
                    const itemDate = dayjs(item.date)
                    const isAfterStartDate = filterDateSince !== "" ? itemDate.isAfter(dayjs(filterDateSince)) : true
                    const isBeforeEndDate = filterDateTo !== "" ? itemDate.isBefore(dayjs(filterDateTo)) : true
                    return isAfterStartDate && isBeforeEndDate
                })
                return returnData
            }
        },
        [filter]
    )

    const filterData = useCallback(
        (data: Rechnung[]) => {
            if (filter) {
                return data?.filter((item) => filterType === "" || filterType === item.billType)
            } else {
                return data
            }
        },
        [filter]
    )

    const filterArchived = useCallback(
        (data: Rechnung[]) => {
            if (archived === "hidden") {
                return data?.filter((item) => item.archived === false)
            } else if (archived === "show") {
                return data
            } else if (archived === "only") {
                return data?.filter((item) => item.archived === true)
            } else {
                return data
            }
        },
        [filter]
    )

    // NOTE currently only search function for customer - maybe for bill title needed as well?
    const displayedBills = useMemo(() => {
        if (data?.docs) {
            const sortData = sortDates(data.docs)
            const archivedData = filterArchived(sortData)
            const filteredData = filterData(archivedData)
            return filterDates(filteredData)
        }
    }, [sortDates, data, filterData, filterDates])

    return (
        <BasePage>
            <HeadBanner
                title={"Rechnungen"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden md:block"
                            onClick={() => modalCon?.trigger(1)}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative max-h-[18vh]">
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => handleInputChange(setsearchTermCustomer, setsearchTermNr, setsearchTermObject, e.target.value)}
                        type="text"
                        value={searchTermCustomer ?? null}
                        placeholder="Nach Kunden suchen..."
                        className="hidden xl:block w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => handleInputChange(setsearchTermObject, setsearchTermCustomer, setsearchTermNr, e.target.value)}
                        type="text"
                        value={searchTermObject ?? null}
                        placeholder="Nach Objekt suchen..."
                        className="hidden md:block w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => handleInputChange(setsearchTermNr, setsearchTermObject, setsearchTermCustomer, e.target.value)}
                        type="text"
                        value={searchTermNr ?? null}
                        placeholder="Nach Rechnungsnummer suchen..."
                        className="w-full sm:w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/angebote")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                        </svg>
                        Angebote
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/zahlungen")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                            />
                        </svg>
                        Zahlungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setsort(!sort)
                        }}>
                        {sort ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                            </svg>
                        )}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <BillFilterModal
                        visible={showFilter}
                        dateSince={filterDateSince}
                        dateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        changeDateSince={setfilterDateSince}
                        useFilter={handleUseFilter}
                        filterType={filterType}
                        filterChangeType={handleChangeType}
                        filterRemove={handleRemoveFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                        archived={archived}
                        setarchived={setarchived}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Erstellungsdatum</th>
                                    <th className=" text-base w-fit text-center truncate">{window.innerWidth > 640 ? "Rechnungsnummer" : "Nr."}</th>
                                    <th className="hidden lg:table-cell text-base min-w-[9rem] w-fit text-center">Typ</th>
                                    <th className=" text-base sm:min-w-[8rem] w-fit text-center">Betrag</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Objekt</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedBills?.map((item) => (
                                    <BillTableRow
                                        key={item._id}
                                        id={item._id}
                                        title={item.nr}
                                        customer={item?.customer_id?.customer?.name}
                                        date={item.date}
                                        delivery={item.serviceDate}
                                        object={item?.object_id}
                                        amount={item.positions}
                                        type={item.billType}
                                        archived={item.archived}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedBills || displayedBills.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erstellten Rechnungen</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={15}
                            defaultCurrent={1}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <AddBillModal />
        </BasePage>
    )
}
